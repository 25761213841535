@import url(https://fonts.googleapis.com/css?family=Nunito:400,400i,600,600i,800,800i|Oswald:200,400,500,700);

$base-font-family      		: 'Nunito', sans-serif;
$title-font-family    		: 'Oswald', sans-serif;
$subtitle-font-family 	 	: 'Oswald', sans-serif;


$base-font-size						: 16px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$header-menu-font 				: 1rem /*0.778rem/1*/ $title-font-family;
$header-search-field-font : 1rem/1.286 $title-font-family;

$nav-base-font        	 	: 1.25rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$breadcrumb-font 					: 0.875rem/1.250 $base-font-family;

$section-row-title-font 	: 1.5rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller 	: 500 2rem/1.25 $title-font-family; // /* Nugget title */ 

$side-row-title-font 			: 1.625rem/2 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family		: $base-font-family;
$card-title-font        	: 600 1.125rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.125rem/1 $base-font-family;
$card-feed-link-font			: 400 1.125rem/1.5 $subtitle-font-family;

$button-link-font 				: 600 $base-font-size/1 $title-font-family;

/* FORMS */
$button-font 							: 600 $base-font-size/1 $title-font-family;
