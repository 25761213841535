// /* DEFAULT CARD LAYOUT	*/
.card {
	border-radius: $card-border-radius;
	margin-bottom:$card-margin-bottom;
	
	transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
			line-height: 30px;
		}
	}	
	.card-text {
		border:$card-border;
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:0 0 1em;
	font-weight: 500;
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:18px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	border: 2px solid $coral_red;
	a {
		padding: 8px 20px;
		display: block;
		&:after {
			content: "";
			display: inline-block;
			width: 12px;
			height: 16px;
			//border-radius: 50%;
			margin-left: 10px;
			// vertical-align: sub;
		}
	}
}
.palette-highlight .card-feed-link {
	border: 2px solid $white;
	&:hover {
		border: 2px solid $coral_red;
	}
}



// /* INNER CARD ELEMENTS */
.tweet {
	.card-text {
		display:block;
		font:normal 1.125em/1.22222222222222 $base-font-family;
	}
	.card-date {
		color: $nickel;
		margin-top: 1em;
		font-weight: 500;
		font-family: $subtitle-font-family;
		em {
			font-style: inherit;
		}
	}
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 600;
	font-size: 1em;
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

.card-role {
	font-family: $subtitle-font-family;
	color: $nickel;
}

.card-published-by {
	color: $purple_navy;
	margin-bottom: 0.5em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
@include card-hidden-summary;

// Row max 1 overrides
main,
.postscript-first {
	.palette-highlight, .palette-default, .palette-alt-1, .palette-alt-2, .palette-alt-3 {
		&.section-row.row-max-1 {
			.card {
				border: none;
				box-shadow: none;
				.card-image {
					border: none;
					margin-right: 2px !important;
				}
				.card-text {
					border: 1px solid $light_gray;
					box-shadow: none;
				}
				&:hover {
					box-shadow: none;
					.card-text {
						box-shadow: none;
						border: 1px solid $coral_red;
					}
				}
			}
		}
	}
}

.postscript-first .section-row .view-id-team .card-title-et {
	margin-bottom: 1em;
}
