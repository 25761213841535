// /* CARD GENERAL */
@mixin card-hover() {
	// background-color:$card-hover-bg-color;
	// border:$card-hover-border;
	// box-shadow:$card-hover-shadow;

/*	* {
		color:$card-text-hover-color;		
	}*/

	.card-text,
	.card-text * {
		// color:$card-text-hover-color;
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $card-action-hover;
		}
	}

	.card-display-date {
		// background:$card-date-hover-bg-color;
		// color: $card-date-hover-text-color;
	}
}


// /* CARD BASE COLOUR OPTIONS */



@mixin card-default() {
	background-color: $card-light-bg-color;
	box-shadow: inset 0px 0px 0px 1px $card-light-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-light-title-color;
	}
	
	.card-image, .card-photo, .card-logo {
		border-bottom: 2px solid $palette-default-bg-color;
	}

	.card-text {
		box-shadow: inset 0px 1px 0px 0px $card-light-border-color;
		color:$card-light-text-color;

		a {
			color:$card-light-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-light-title-color;
			}
			&:hover {
				color:$card-light-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-light-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-light-bg-color;
		}
	}

	.card-subtitle {
		color: $card-light-subtitle-color;
	}
	&:hover {
		box-shadow: inset 0px 0px 0px 1px $coral_red;
		.card-text {
			box-shadow: inset 0px 1px 0px 0px $coral_red;
		}
	}
}

@mixin card-alt-1() {
	background-color: $card-medium-bg-color;
	box-shadow: inset 0px 0px 0px 1px $card-medium-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-medium-title-color;
	}

	.card-image, .card-photo, .card-logo {
		border-bottom: 2px solid $palette-alt-1-bg-color;
	}

	.card-text {
		color:$card-medium-text-color;
		box-shadow: inset 0px 1px 0px 0px $card-medium-border-color;

		a {
			color:$card-medium-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-medium-title-color;
			}
			&:hover {
				color:$card-medium-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-medium-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-medium-bg-color;
		}
	}

	.card-subtitle {
		color: $card-medium-subtitle-color;
	}

	&:hover {
		box-shadow: inset 0px 0px 0px 1px $coral_red;
		.card-text {
			box-shadow: inset 0px 1px 0px 0px $coral_red;
		}
	}
}

@mixin card-alt-2() {
	background-color: $card-dark-bg-color;
	box-shadow: inset 0px 0px 0px 1px $card-dark-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-dark-title-color;
	}

	.card-image, .card-photo, .card-logo {
		border-bottom: 2px solid $palette-alt-2-bg-color;
	}

	.card-text {
		color:$card-dark-text-color;
		box-shadow: inset 0px 1px 0px 0px $card-dark-border-color;

		a {
			color:$card-dark-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-dark-title-color;
			}
			&:hover {
				color:$card-dark-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-dark-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-dark-bg-color;
		}
	}

	.card-subtitle {
		color: $card-dark-subtitle-color;
	}

	&:hover {
		box-shadow: inset 0px 0px 0px 1px $coral_red;
		.card-text {
			box-shadow: inset 0px 1px 0px 0px $coral_red;
		}
	}
}

@mixin card-highlight() {
	background-color: $card-highlight-bg-color;
	box-shadow: inset 0px 0px 0px 1px $card-highlight-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-highlight-title-color;
	}

	.card-image, .card-photo, .card-logo {
		border-bottom: 2px solid $palette-highlight-bg-color;
	}

	.card-text {
		color:$card-highlight-text-color;
		box-shadow: inset 0px 1px 0px 0px $card-highlight-border-color;

		a {
			color:$card-highlight-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-highlight-title-color;
			}
			&:hover {
				color:$card-highlight-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-highlight-action-hover;
				}
			}
		}
		.card-display-date,
		.card-published-by,
		.card-role,
		.card-date {
			color: $white;
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-highlight-bg-color;
		}
	}

	.card-subtitle {
		color: $card-highlight-subtitle-color;
	}

	&:hover {
		box-shadow: inset 0px 0px 0px 1px $coral_red;
		.card-text {
			box-shadow: inset 0px 1px 0px 0px $coral_red;
		}
	}
}

@mixin card-alt-3() {
	background-color: $card-alt-3-bg-color;
	box-shadow: inset 0px 0px 0px 1px $card-alt-3-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-alt-3-title-color;
	}

	.card-image, .card-photo, .card-logo {
		border-bottom: 2px solid $palette-alt-3-bg-color;
	}

	.card-text {
		color:$card-alt-3-text-color;
		box-shadow: inset 0px 1px 0px 0px $card-alt-3-border-color;

		a {
			color:$card-alt-3-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-alt-3-title-color;
			}
			&:hover {
				color:$card-alt-3-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-alt-3-action-hover;
				}
			}
		}
		.card-display-date,
		.card-published-by,
		.card-date,
		.card-role {
			color: $white;
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-alt-3-bg-color;
		}
	}

	.card-subtitle {
		color: $card-alt-3-subtitle-color;
	}

	&:hover {
		box-shadow: inset 0px 0px 0px 1px $card-alt-3-bg-color;
		.card-text {
			box-shadow: inset 0px 1px 0px 0px $card-alt-3-bg-color;
		}
	}
}
// /* CARD MORE LINKS */
@mixin card-more-link-default() {
	a.card {
		background:$card-more-link-default-bg;
		color: $card-more-link-default-color;
		&:after {
			background: $card-more-link-default-icon;
		}
		&:hover {
			background:$card-more-link-default-hover-bg;
			color: $card-more-link-default-hover-color;
			&:after {
				background: $card-more-link-default-hover-icon;
			}
		}
	}
}

@mixin card-more-link-alt() {
	a.card {
		background:$card-more-link-alt-bg;
		color: $card-more-link-alt-color;
		&:after {
			background: $card-more-link-alt-icon;
		}
		&:hover {
			background:$card-more-link-alt-hover-bg;
			color: $card-more-link-alt-hover-color;
			&:after {
				background: $card-more-link-alt-hover-icon;
			}
		}
	}
}

@mixin card-more-link-alt-2() {
	a.card {
		background:$card-more-link-alt-2-bg;
		color: $card-more-link-alt-2-color;
		&:after {
			background: $card-more-link-alt-2-icon;
		}
		&:hover {
			background:$card-more-link-alt-2-hover-bg;
			color: $card-more-link-alt-2-hover-color;
			&:after {
				background: $card-more-link-alt-2-hover-icon;
			}
		}
	}
}


// /* CARD FEED MORE LINKS */
@mixin card-feed-link-default() {
	a {
		background:$card-feed-link-default-bg;
		color: $card-feed-link-default-color;
		&:after {
			background: $card-feed-link-default-icon;
		}
		&:hover {
			background:$card-feed-link-default-hover-bg;
			color: $card-feed-link-default-hover-color;
			&:after {
				background: $card-feed-link-default-hover-icon;
			}
		}
	}
}


@mixin card-feed-link-alt() {
	a {
		background:$card-feed-link-alt-bg;
		color: $card-feed-link-alt-color;
		&:after {
			background: $card-feed-link-alt-icon;
		}
		&:hover {
			background:$card-feed-link-alt-hover-bg;
			color: $card-feed-link-alt-hover-color;
			&:after {
				background: $card-feed-link-alt-hover-icon;
			}
		}
	}
}
