@mixin card-hidden-summary {
	.palette-default 		{ 
		.card:hover .card-text.card-with-summary { 
			background:$card-light-bg-color !important;
			box-shadow: inset 0px 0px 0px 1px $coral_red !important;
		} 
	};
	.palette-highlight 	{ 
		.card:hover .card-text.card-with-summary { 
			background:$card-highlight-bg-color !important;
			box-shadow: inset 0px 0px 0px 1px $coral_red !important;
		} 
	};
	.palette-alt-1 			{ 
		.card:hover .card-text.card-with-summary { 
			background:$card-medium-bg-color !important;
			box-shadow: inset 0px 0px 0px 1px $coral_red !important;
		} 
	};
	.palette-alt-2 			{ 
		.card:hover .card-text.card-with-summary { 
			background:$card-dark-bg-color !important;
			box-shadow: inset 0px 0px 0px 1px $coral_red !important;
		} 
	};
	.palette-alt-3 			{ 
		.card:hover .card-text.card-with-summary { 
			background:$card-alt-3-bg-color !important;
			box-shadow: inset 0px 0px 0px 1px $card-alt-3-bg-color !important;
		} 
	};	
	.section-row:not(.row-max-1) {
		@media (min-width:540px) {
			.view-links .card {
				.card-text.card-with-summary {
					height: 100% !important;
					width: 100%;

					.card-summary-wrapper {
						min-height: 100%;
						width: 100%;
						opacity:0;
						height: 0;
						position: relative;
						overflow: hidden;
						//transition: all 0.375s ease-in;
						line-height: 20px;
						margin-top: 1em;
					}
				}
				.card-image + .card-with-summary,
				.card-photo + .card-with-summary,
				.card-logo + .card-with-summary {
					position: relative;
					height: auto;
				}

				&:hover {
					.card-text.card-with-summary {
						// background: $card-text-with-summary-hover-bg !important;
						// color: $card-text-with-summary-text-color !important;
						text-overflow: ellipsis;
						width: 100%;
						
							.card-title,
							.card-title-et,
							.card-title-field {
								//color: $card-text-with-summary-title-color !important;
								display: none;
							}

						
						.card-summary-wrapper {
							opacity:1;
							height: auto;
							transition: all 0.375s ease-in ;
						}
					}

					.card-image +.card-text.card-with-summary,
					.card-logo +.card-text.card-with-summary {
						position: absolute;
						bottom: 0;
						height: auto !important;
								
						//top: 0;
						//height: 100% !important;
					}
				}
			}
		}
	}
}
