/*
 * @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 *
 * Do not name this file "STARTER.scss". This file should be named
 * remora.scss. If you used the drush command to generate this subtheme,
 * it should be named for you.
 *
 * This application file (remora.scss) is where all the partials are
 * imported.
 *
 * If you have changed the paths where bower components live, please update the mappings below.
 */

* {-webkit-tap-highlight-color: rgba(0,0,0,0);}

// Make sure the charset is set appropriately
@charset "UTF-8";

// Import normalize.css
@import "../../../../all/themes/neato/bower_components/normalize.css/normalize.css";

// Import Bourbon.
@import "../../../all/themes/neato/bower_components/bourbon/app/assets/stylesheets/bourbon";

// Import Neat.
@import "../../../all/themes/neato/bower_components/neat/app/assets/stylesheets/neat";
@import "../../../all/themes/neato/bower_components/neat/app/assets/stylesheets/neat-helpers";

// Import Variables.
@import "variables/variables";

// Import Mixins.
@import "mixins/mixins";

// Import Base.
@import "base/base";

// Import common page elements
@import "common/common";

// Import your components here.
@import "components/components";

// Import Features.
@import "features/features";

// Import Layout.
@import "layout/layout";
