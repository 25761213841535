// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		
		@media (min-width:880px) and (max-width:1150px) {
			min-height:46px;
		}

		@include media(1150px) {
			min-height:46px;
		}
	}
}

// MENU STYLES
#site-header {
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		position: relative;
		
		.main-menu-btn {
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			margin: -102px 0px 0px 0px;
						
			.main-menu-btn-icon {
 				left:16px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;
				
				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
				
				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			} 
		}
		
		ul.sm {
			background: none; 
			clear:both;
		}
		
		@include media(880px) { 
			clear:right;
			float:right;
		}		
		
		.menu-block-wrapper ul.menu.sm  {
			
			border:none;
			box-shadow:none;
					
			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;

				&:last-child { 
					position:relative;
				}

				@include media(880px) { 
					& > a > .sub-arrow {
						display:none;
					}
				}

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					// text-transform: uppercase;
					font-weight: 500;

					@media (min-width:880px) and (max-width:1150px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}			
					&.current,
					&.active.current.highlighted {
						background-color:$nav-active-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;
					}

					&.current.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-radius:$nav-active-border-radius;
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-bottom: $nav-highlight-border;
						border-top: none;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							color:$nav-highlight-text-color;
						}
					}
				}
			}
			
			li {
				a {
					cursor:pointer; 
					background-color: transparent;
										
					.sub-arrow {
						background:$arrow-white-right no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
				
				ul.menu {	
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;
					
					li {
						border-top:none; 
						
						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;
							
							&.current {
								background:$nav-sub-bg-color;
							}
							
							&:hover, 
							&.active {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							} 
							
							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:contain;
								text-indent:-999em;
								overflow:hidden;
								right:10px;				
							}	
							
							&.active {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							
							&:hover, 
							&.active:hover  {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}								
							}							
						}						
						&.expanded {
							
							& > a.highlighted { 
								background-color:$nav-hover-bg-color;
								color:$nav-hover-text-color;

								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}

								&:hover, 
								&.current.active {
									background-color:$nav-sub-hover-bg-color;
									color:$nav-hover-text-color;
								}
							}
						}
					}
				}
			}
			
		}	
		
////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {
		
			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {
				
				// border: $nav-mob-border;
				border: none;
				border-top: none; 
				box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
				
				position: absolute;
				top: 2px;
				right: 0;
				width: calc(100% - 5%); 
				max-width: 400px;
				z-index: 1005;
				
				@media (min-width:$narrow) and (max-width:879px) {
					top: 0;
				}
				
				& > li {
					
					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						border: none;
					}
					
					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-sub-text-color;
						// padding:$nav-sub-base-padding;
						padding: 16px 20px;
						// text-transform: uppercase;
						font-weight: 500;

						&:hover {
							background-color:$nav-sub-hover-bg-color;
							color:$nav-sub-hover-text-color;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}	
						}

						&.current { 
							background:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							border-top:$nav-mob-expanded-border;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {						
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						}

						&.active { 
							background-color:$nav-mob-active-bg-color;
							color:$nav-active-text-color;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								} 
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							.sub-arrow {
								background:$arrow-white-up no-repeat scroll center center transparent;
								background-size:50%;						
							}
							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-up no-repeat scroll center center transparent;
									background-size:50%;
								}	
							}
						}

						&.active-trail,
						&.current.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-highlight-border;
							// padding:$nav-sub-base-hover-padding;
							padding: 16px 20px;

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
						}

						&.active.highlighted {
							.sub-arrow {
								background:$arrow-white-up no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-up no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						} 
					}
				}
				
				li {
					border-top: $nav-mob-border;
					
					a {
						.sub-arrow {
							background:$arrow-white-down no-repeat scroll center center transparent;
							background-size:50%;
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}
					
					
					///////////////////////////////      NEXT LEVELS      ///////////////////////////////
					
					/* ul BACKGROUNDS */
					&.expanded {
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							//border-top:$nav-mob-expanded-borde
							//border-bottom:$nav-mob-expanded-border;

							a.active {
								background-color: $yale_blue_dark;
							}

							li.expanded {
								ul.menu {	
									background-color: $nav-mob-3rd-bg-color;
									//border-top:$nav-mob-expanded-border;
									//border-bottom:$nav-mob-expanded-border;

									li:not(.first) {
										border-top:$nav-mob-expanded-border;
									}

									a.active {
										background-color: $nav-mob-3rd-bg-color;
										color: $action-hover;
									}
								}

								li.expanded {
									ul.menu {	
										background-color: $nav-mob-4rd-bg-color;
										//border-top:$nav-mob-expanded-border;
										//border-bottom:$nav-mob-expanded-border;

										li:not(.first) {
											border-top:$nav-mob-expanded-border;
										}

										a.active {
											background-color: $nav-mob-4rd-bg-color;
											color: $action-hover;
										}
									}
								}
							}
						}
					}
					
					/* ARROWS + HIGHLIGHT */ 
					ul.menu {	
						box-shadow: none;
						
						li {
							border-top:$nav-mob-expanded-border;
							
							a {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
									right:0;
									height: 39px;
									margin-top: -19px;
									//border-left: $nav-mob-border;
								}	
															
								&.active {
									.sub-arrow {
										background:$arrow-white-down no-repeat scroll center center transparent;
										background-size:50%;
									}
								}

								&:hover, 
								&.active:hover  {
									.sub-arrow {
										background:$arrow-white-down no-repeat scroll center center transparent;
										background-size:50%;
									}								
								}
							}
							
							&.expanded {								
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;
										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}
								
								////////////**     2nd LEVEL     **////////////
								& > a.highlighted { 
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color;
									//border-top:$nav-mob-expanded-border;

									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover, 
									&.current.active {
										background-color:$yale_blue_dark;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;
									//border-top:$nav-mob-expanded-border;

									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover, 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}								
								}

								////////////**     4th LEVEL     **////////////
								& > ul.menu > li.expanded > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-4rd-highlight-bg-color;
									color: $nav-mob-4rd-highlight-text-color;
									//border-top:$nav-mob-expanded-border;

									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover, 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}								
								}
							}
						}
					}
				}
			}
		}
	}
}

// Menu overrides
@include media(880px) {
	#site-header .block-menu-block .menu-block-wrapper {
		ul.menu.sm > li > a.current {
			background-color: $header-bg-color;
			&.active,
			&.highlighted,
			&.active-trail {
				  &:before,
			    &:after {
			      border-top: 2px solid $coral_red;
			      content: "";
			      display: inline-block;
			      padding-bottom: 7px;
			      width: 14px;
			      z-index: -1;
			      margin: 0 2px;
			    }
			    &:hover {
			    	background-color: $white;
			    	color: $yale_blue_dark;
			    }
		  }
		}
		ul.menu.sm {
			> li {
				> a {
					&:hover,
					&.highlighted:hover {
						background-color: $white;
				  	color: $yale_blue_dark;
					}
				}
			}
			li {
				ul.menu {
					li {
						a {
							&.active-trail,
							&.active,
							&.current {
								background-color: $denim;
								color: $white;
								&:hover {
									background-color: $denim;
							  	color: $white;
								}
							}
							&:hover {
								background-color: $denim;
								color: $white;
							}
						}
						&.expanded {
							a {
								&.highlighted {
									background-color: $denim;
							  	color: $white;
							  	&:hover {
							  		background-color: $denim;
								  	color: $white;
							  	}
								}
							}
						}
					}
				}
			}
		}
	}
}

















