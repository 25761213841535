/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px;	
		box-sizing: border-box;
		font-size: 1.125rem;
		line-height: 24px;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		font-size: 1.250rem;
		font-weight: 600;
		text-decoration: none;
		color: $action-default;
		padding: 10px 0 10px 30px;
		line-height: 20px;
		display: block;
		/*border: 1px solid #d0d0d0;*/
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand no-repeat scroll 0 50% / 16px 16px transparent;

		&.active {
			// font-weight: bold;
			color: $coral_red;
			background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
			&:hover {
				background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
			}
		}
		&:hover {
			color: $coral_red;
			background: $ico-expand-hover no-repeat scroll 0 50% / 16px 16px transparent;
		}
	}
	
	/*.ico-collapse, .ico-expand {
		width: 20px;
		height: 20px;
		float:left;
		margin-right: 20px;
	}

	.ico-collapse {
		
	}
	.ico-expand {
		
	}*/

}
.palette-highlight .view-faqs {
	.card-qa-question {
		color: $white;
		background: $ico-expand-white no-repeat scroll 0 50% / 16px 16px transparent;
		&.active {
			color: $coral_red;
			background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
			&:hover {
				background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
			}
		}
		&:hover {
			color: $coral_red;
			background: $ico-expand-hover no-repeat scroll 0 50% / 16px 16px transparent;
		}
	}
}
