button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: 2px solid;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 8px 22px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: 2px solid;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 8px 22px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
