.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	// .views-row {
	// 	padding: $vert-space/2 0;
	// }
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			float:left;
			margin:0 2px 0 0;
			min-width:4rem;
			.file-extension {
				padding: 10px 20px;
				text-transform:uppercase;
				font-size:1em;
        line-height:22px;
				text-align:center;
				font-weight:500;
        background: $white;
        font-family: $subtitle-font-family;
			}
			.file-size {
        padding: 3px 5px;
				font-size:0.750em;			
				text-align:center;
				color:$secondary;
        border:1px solid $light_gray;
        margin-top: 2px;
        background: $white;
			}
		}
		
		.file-name {
			overflow:hidden;
      padding: 10px 20px;
      border: 1px solid $light_gray;
      min-height: 74px;
      background: $white;
      font-size: 1.250rem;
      font-weight: 600;
      line-height: 30px;
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background: $white;
      color:$docx_color;
      border: 2px solid $docx_color;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background: $white;
      color:$xlsx_color;
      border: 2px solid $xlsx_color;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background: $white;
      color:$pptx_color;
      border: 2px solid $pptx_color;
    }
  }
  .file-type-pdf {
    .file-extension {
      background: $white;
      color:$pdf_color;
      border: 2px solid $pdf_color;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background: $white;
      color:$txt_color;
      border: 2px solid $txt_color;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background: $white;
      color:$jpg_color;
      border: 2px solid $jpg_color;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension {
     background: $white; 
      color:$zip_color;
      border: 2px solid $zip_color;
    }
  }
} 


//HIGHLIGHT PALETTE
 .palette-highlight .view-attachments {
  .attachment-link {
    .file-extension {
      border: 2px solid $white;
    }
    .file-size {
      color: $white;
      border: 1px solid $white;
      background: $primary;
    }
    .file-name {
      background: $primary;
    }
    &:hover {
      .file-name {
        border: 1px solid $coral_red;
      }
    }
  }
  .file-type-docx, .file-type-doc {
    .file-extension {
      color:$docx_color;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      color:$xlsx_color;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      color:$pptx_color;
    }
  }
  .file-type-pdf {
    .file-extension {
      color:$pdf_color;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      color:$txt_color;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      color:$jpg_color;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension {
      color:$zip_color;
    }
  }
}

.postscript-first {
  .view-attachments .attachment-link {
    @media (min-width:1600px) {
      width: calc(25% - 10px);
      float: left;
      margin: 0 10px 10px 0;
    }

    @media (min-width:940px) and (max-width:1599px) {
      width: calc(50% - 20px);
      float: left;
      margin: 0 10px 10px 0;
    }
  }
  .view-attachments .views-row:nth-child(4n+1) {
    clear: left;
  }
}

main {
  .view-attachments .attachment-link {
    @media (min-width:1600px) {
      width: calc(50% - 20px);
      float: left;
      margin-right: 20px;
    }
    @media (max-width:1599px) {
      width: 100%;
      float: none;
      margin-right: 0;
    }
  }
  .view-attachments .views-row-odd {
    clear: left;
  }
}
main #sidebar-second {
  .view-attachments .attachment-link {
    width: 100%;
    float: none;
    margin-right: 0;
  }
}
