 main {
	 #sidebar-second {
		background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row {
		background: $side-row-bg;
		border: $side-row-border;
		border-radius: $side-row-border-radius;
		box-shadow: $side-row-box-shadow;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;

		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;

		 &.section-with-slider {
			 padding:0;
		 }

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
		}

		h2 {
			font:$side-row-title-font;
			.card-title {
				font-size: inherit;
			}
		}
		.card-more-link a.card {
			border: 1px solid $light_gray;
			padding: 20px;
			text-align: center;
			font-size: 1.250rem;
			&:hover {
				border: 1px solid $coral_red;
				&:after {
					background: $card-more-link-default-hover-icon;
				}
			}
			&:after {
				content:" ";
				display:inline-block;
				height:18px;
				margin:0 0 -2px 10px;
				width:14px;
				background: $card-more-link-default-icon;
			}
		}

		.card {
			@include palette-sidebar-cards;

			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			.card-image {
				border-bottom: 2px solid $anti_flash_white;
			}
			.card-text {
				padding: $card-text-padding;
		    box-shadow: inset 0px 0px 0px 1px $light_gray;
			}
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-sidebar-card-more-link;
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}
		.card-title h2 {
			margin: -20px -20px 20px -20px;
			padding: 10px 20px;
			background: $white;
			border: 1px solid $secondary;
			text-align: center;
		}
	}
} 

main .view-id-related {
	.view-header .side-row {
	  margin: 0;
	  padding: 10px 20px;
	  background: $white;
	  border: 1px solid $secondary;
	  text-align: center;
	  h2 {
	  	margin: 0;
	  }
	}
	.view-content-main {
		background: $side-row-bg;
		border: $side-row-border;
		margin: $side-row-margin;
		padding:$side-row-padding;
	}
}
