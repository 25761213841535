// Font styles for the following can be configured in var-typography

// /* HEADER */ //
$header-bg-color                   : $secondary;

$header-menu-link-color            : $white;
$header-menu-link-hover            : $black;

$header-search-field-bg-color      : $secondary;
$header-search-field-text-color    : $white;
$header-search-field-box-shadow    : none;
$header-search-field-border        : 1px solid $white;
$header-search-field-border-radius : 0;


// /* MAIN MENU */ //
$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: $white;
$nav-base-padding        			: 0.875rem 1.875rem;
$nav-base-hover-padding   		: 0.875rem 1.875rem;
$nav-sub-base-padding					: 0.625rem 1.250rem;
$nav-sub-base-hover-padding		: 0.625rem 1.250rem;

$nav-base-padding-smaller 				: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.375rem 1.125rem;

$nav-active-bg-color      		: $darker_imperial_blue;
$nav-active-text-color    		: $white;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $white;
$nav-hover-text-color     		: $white;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $darker_imperial_blue;
$nav-highlight-text-color 		: $white; 
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: $darker_imperial_blue;
$nav-sub-hover-bg-color   		: $darker_imperial_blue;
$nav-sub-text-color       		: $white;
$nav-sub-hover-text-color   	: $white;
$nav-sub-box-shadow      			: none;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

$nav-mob-active-bg-color      			: $darker_imperial_blue;

$nav-mob-1st-bg-color     					: $darker_imperial_blue;
$nav-mob-1st-highlight-bg-color     : $denim;
$nav-mob-1st-highlight-text-color   : $white;

$nav-mob-2nd-bg-color     					: $charcoal;
$nav-mob-2nd-highlight-bg-color     : $yale_blue_dark;
$nav-mob-2nd-highlight-text-color   : $white;

$nav-mob-3rd-bg-color     					: $independance;
$nav-mob-3rd-highlight-bg-color     : $independance;
$nav-mob-3rd-highlight-text-color   : $white;

$nav-mob-4rd-bg-color     					: $independance;
$nav-mob-4rd-highlight-bg-color     : $independance;
$nav-mob-4rd-highlight-text-color   : $white;

$nav-mob-border											: 1px solid $white;
$nav-mob-expanded-border						: 1px solid #687382;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;

$nav-button-bg-color						: $white;
$nav-button-hover-bg-color			: $white;
$nav-button-height      				: 100px;
$nav-button-width      					: 86px;
$nav-button-bar-bg-color      	: $white;
$nav-button-bar-hover-bg-color  : $coral_red;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 10px;

// /* BREADCRUMBS */ //
$breadcrumb-bg-color     : $light_cyan;
$breadcrumb-action-color : $yale_blue_dark;
$breadcrumb-hover-color  : $action-hover;
$breadcrumb-active-color : $primary;
$breadcrumb-div-color    : $primary;


// /* PRIMARY TABS */ //
$tab-active-bg-color     : $platinum;
$tab-active-text-color   : $nav-highlight-text-color;
$tab-active-border       : $primary-border;
$tab-inactive-bg-color   : $base-background-color;
$tab-inactive-text-color : $primary;
$tab-hover-bg-color      : $nav-hover-bg-color;
$tab-hover-text-color    : $nav-hover-text-color;
$tab-border              : 1px solid $nav-highlight-bg-color;
$tab-border-bottom       : $primary-border;



// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
// $card-shadow                   : inset 0px 0px 0px 1px red;
// $card-border                   : 1px solid $light_gray;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : transparent;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $nickel;
$card-date-font                : 1em/1 $subtitle-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : $dark_lavender;
$card-hover-border             : none;
$card-hover-shadow             : none;
$card-action-hover             : $coral_red; //colour of card title text when you hover over it
$card-text-hover-color         : $platinum; //colour of regular card text when you hover over it
$card-date-hover-text-color    : lighten($grey,6%);
$card-date-hover-bg-color      : $pale_grey; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- un/comment the appropriate line in base/_cards.scss as appropriate
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : $white;
$card-light-border-color       : $light_gray;
$card-light-title-color        : $yale_blue_dark;
$card-light-action-default     : $secondary;
$card-light-action-hover       : $coral_red; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $primary;
$card-light-subtitle-color     : $primary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $white;
$card-medium-border-color      : $light_gray;
$card-medium-title-color       : $yale_blue_dark;
$card-medium-action-default    : $secondary;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $primary;
$card-medium-subtitle-color    : $primary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $white;
$card-dark-border-color        : $light_gray;
$card-dark-title-color         : $yale_blue_dark;
$card-dark-action-default      : $secondary;
$card-dark-action-hover        : $coral_red; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $primary;
$card-dark-subtitle-color      : $primary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $primary;
$card-highlight-border-color   : $white;
$card-highlight-title-color    : $white;
$card-highlight-action-default : $white;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $white;
$card-highlight-subtitle-color : $white;

// /* --- CARD-ALT-3 -  */
$card-alt-3-bg-color       : $secondary;
$card-alt-3-border-color   : $secondary;
$card-alt-3-title-color    : $white;
$card-alt-3-action-default : $white;
$card-alt-3-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-alt-3-text-color     : $white;
$card-alt-3-subtitle-color : $white;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $white;
$card-more-link-default-color       : $yale_blue_dark;
$card-more-link-default-icon        : $arrow-blue-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : $white;
$card-more-link-default-hover-color : $action-hover;
$card-more-link-default-hover-icon  : $arrow-coral-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $secondary;
$card-more-link-alt-color           : $white;
$card-more-link-alt-icon            : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $secondary;
$card-more-link-alt-hover-color     : $action-hover;
$card-more-link-alt-hover-icon      : $arrow-coral-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-2-bg            : $primary;
$card-more-link-alt-2-color         : $white;
$card-more-link-alt-2-icon          : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-2-hover-bg      : $primary;
$card-more-link-alt-2-hover-color   : $action-hover;
$card-more-link-alt-2-hover-icon    : $arrow-coral-right no-repeat 100% 100% / 14px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none; // background for the entire link
$card-feed-link-default-color       : $action-hover;
$card-feed-link-default-icon        : transparent $arrow-coral-right no-repeat scroll 50% 50% / 12px 16px; //background for the default :after component
$card-feed-link-default-hover-bg    : $action_hover;
$card-feed-link-default-hover-color : $white;
$card-feed-link-default-hover-icon  : transparent $arrow-white-right no-repeat scroll 50% 50% / 12px 16px;

$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : transparent $arrow-white-right no-repeat scroll 50% 50% / 12px 16px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $action-hover;
$card-feed-link-alt-hover-icon      : transparent $arrow-coral-right no-repeat scroll 50% 50% / 12px 16px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : none;
$button-link-default-border-color       : $action-hover;
$button-link-default-color              : $action-hover;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $action-hover;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : $white;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $primary;
$button-link-alt-border-color           : $white;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $white;
$button-link-alt-hover-border-color     : $white;
$button-link-alt-hover-color            : $coral_red;
$button-link-alt-hover-icon             : none;




// /* MAIN PAGE */ //
$hero-bg              : $light_gray;  // /* Should not be the same as $main-page-bg or $palette-default-bg-color for banded sites */
$main-page-bg         : $white;
$section-main-padding : $vert-space*1.5 0 !default;




// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: $white; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $arrow-coral-left no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: $coral_red $arrow-white-left no-repeat scroll 50% 50%;
$slick-default-next-bg            				: transparent $arrow-coral-right no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: $coral_red $arrow-white-right no-repeat scroll 50% 50%;
$slick-default-dot-border         				: 2px solid $secondary;
$slick-default-dot-hover-border    				: 2px solid $coral_red;
$slick-default-dot-active-bg-color 				: $coral_red;
$slick-default-dot-active-hover-bg-color 	: $coral_red;


$slick-alt-1-nav-bg                				: none; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: transparent $arrow-coral-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: $coral_red $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: transparent $arrow-coral-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: $coral_red $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border    				: 2px solid $white;
$slick-alt-1-dot-hover-border      				: 2px solid $white;
$slick-alt-1-dot-active-bg-color   				: $white;
$slick-alt-1-dot-active-hover-bg-color 		: $white;


$slick-alt-2-nav-bg                 			: $pale_grey; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $faded_blue $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $dark_lavender $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $faded_blue $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $dark_lavender $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 2px solid $faded_blue;
$slick-alt-2-dot-hover-border      				: 2px solid $faded_blue;
$slick-alt-2-dot-active-bg-color   				: $faded_blue;
$slick-alt-2-dot-active-hover-bg-color 		: $faded_blue;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgb(255, 255, 255) ;
$slide-text-color          : $primary;
$slide-text-font           : 1.625rem/1.45 $base-font-family;



// /* SIDEBAR Container styles */

$sidebar-second-bg       : none;
$sidebar-second-border   : none;
$sidebar-second-margin   : 0;
$sidebar-second-padding  : 0;

$side-row-bg                   	: $anti_flash_white;
$side-row-border               	: 1px solid $light_gray;
$side-row-border-radius        	: none;
$side-row-box-shadow 						: none;
$side-row-padding              	: $vert-space $horz-space;
$side-row-inner-mobile-padding 	: 0;
$side-row-inner-padding        	: 0;
$side-row-margin               	: 0 0 $vert-space*1.25;



/* SIDEBAR CARDS */
@mixin palette-sidebar-cards {
	@include card-default();
}
@mixin palette-sidebar-card-more-link {
	@include card-more-link-default();
}
@mixin palette-sidebar-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-sidebar-button-link {
	@include button-link-default();
}
@mixin palette-sidebar-slick-controls {
	@include slick-controls-default();
}

$card-sidebar-border        : 0;
$card-sidebar-border-radius : 0;
$card-sidebar-shadow        : none;
$card-sidebar-text-padding  : $vert-space $horz-space !default;
$card-sidebar-margin-bottom : $vert-space !default;



// /* SECTION/NUGGET ROWS */ /
$section-row-padding          : $vert-space*2 0;
$section-inner-mobile-padding : 0 $horz-space/2;
$section-inner-padding        : 0 $horz-space;

// /*Default sets the card colours for main and additional also */
$palette-default-bg-color      : $white;
$palette-default-title-color   : $secondary;
$palette-default-text-color    : $primary;
$palette-default-link-color	   : $action-default;
$palette-default-link-hover    : $action-hover;
$palette-default-image-border  : $palette-default-bg-color;

//
// --- Set the card style for the DEFAULT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-default-cards {
	@include card-default();
}
@mixin palette-default-card-more-link {
	@include card-more-link-default();
}
@mixin palette-default-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-default-button-link {
	@include button-link-default();
}
@mixin palette-default-slick-controls {
	@include slick-controls-default();
}


$palette-alt-1-bg-color        : $azureish_white;
$palette-alt-1-title-color     : $secondary;
$palette-alt-1-text-color      : $primary;
$palette-alt-1-link-color      : $action-default;
$palette-alt-1-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-1 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-1-cards {
	@include card-alt-1();
}
@mixin palette-alt-1-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-1-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-1-button-link {
	@include button-link-default();
}
@mixin palette-alt-1-slick-controls {
	@include slick-controls-default();
}


$palette-alt-2-bg-color        : $anti_flash_white;
$palette-alt-2-title-color     : $secondary;
$palette-alt-2-text-color      : $primary;
$palette-alt-2-link-color	     : $action-default;
$palette-alt-2-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-2 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-2-cards {
	@include card-alt-2();
}
@mixin palette-alt-2-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-2-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-2-button-link {
	@include button-link-default();
}
@mixin palette-alt-2-slick-controls {
	@include slick-controls-default();
}


$palette-highlight-bg-color    : $primary;
$palette-highlight-title-color : $white;
$palette-highlight-text-color  : $white;
$palette-highlight-link-color	 : $white;
$palette-highlight-link-hover  : $action-hover;

//
// --- Set the card style for the HIGHLIGHT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-highlight-cards {
	@include card-highlight();
}
@mixin palette-highlight-card-more-link {
	@include card-more-link-alt-2();
}
@mixin palette-highlight-card-feed-link {
	@include card-feed-link-alt();
}
@mixin palette-highlight-button-link {
	@include button-link-alt();
}
@mixin palette-highlight-slick-controls {
	@include slick-controls-alt-1();
}


$palette-alt-3-bg-color        : $white;
$palette-alt-3-title-color     : $secondary;
$palette-alt-3-text-color      : $primary;
$palette-alt-3-link-color	     : $action-default;
$palette-alt-3-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-3 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-3-cards {
	@include card-alt-3();
}
@mixin palette-alt-3-card-more-link {
	@include card-more-link-alt();
}
@mixin palette-alt-3-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-3-button-link {
	@include button-link-default();
}
@mixin palette-alt-3-slick-controls {
	@include slick-controls-default();
}


// /* SEARCH */ //
$search-card-bg-color : white;


// /* HIGHCHARTS */ //
$highcharts-font-family      : 'Georgia', serif !default;
$highcharts-container-margin : $vert-space*1.5 0 !default;


// /* FOOTER */ //
$footer-first-bg           : $secondary;
$footer-first-text         : white;
$footer-first-action       : white;
$footer-first-action-hover : $pale_grey;
$footer-first-border       : none;

//
// --- Set the card style for FOOTER-FIRST by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-first-cards {
	@include card-default();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-alt();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-alt-1();
}


$footer-second-bg           : $black;
$footer-second-text         : white;
$footer-second-action       : white;
$footer-second-action-hover : $pale_grey;

//
// --- Set the card style for FOOTER-SECOND by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-second-cards {
	@include card-default();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-default();
}

// /* FOOTER CARD-LINKS  -- Follow . social media icons */
$card-links-action-bg            : transparent;
$card-links-border-radius        : 0;
$card-links-width                : 40px;
$card-links-height               : 40px;
$card-links-hover-opacity        : 0.8;
$card-links-hover-border-radius  : 0;
$card-links-hover-bg             : transparent;


$footer-third-bg            : white;
$footer-third-text          : $primary;
$footer-third-action        : $action-default;
$footer-third-action-hover  : $action-hover;
