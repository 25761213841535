.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }
////.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
////.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }


#site-header {
	background-color:$header-bg-color; 
	box-shadow: inset 0px -2px 0px 0px $coral_red;
	@include media(880px) {
		box-shadow: none;
	}
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-outer-wrapper {
		position:relative;
		@include outer-container($site-max-width);
	}
	.wrapper-region-header {
		float: right;
	}
	.site-branding {
		margin-top:0;
		margin-left:1.25rem;
		position: relative;
		.site-title {
			position:absolute;
			left:0;
			top:4.5rem;
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:270px;
				height:80px;
			}
			@include media(880px) {
				top: 2.5rem;
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	.block-search {
		float:right;
		height:10rem;
		position: relative;
		@include media(880px) {
			height:auto;
		}
		h2 {
			font-family:$base-font-family;
			font-size:1rem;
			color:$primary;
			float:left;
			line-height:1.5;
			margin-top:1rem;
			margin-right:0.75rem;
			cursor:pointer;
			display:none;
			@include media(880px) {
				margin-right:2.5rem;
			}
		}
		.form-wrapper {
			display:none;
			@include media(880px) {
				display:block;
			}
		}
		.form-item-search-block-form {
			margin-top:0.75rem;
			margin-right:0;
			margin-bottom:0;
			@include media(880px) {
				margin-bottom:0;
			}
			@include media($wide) {
				margin-bottom:0;
			}
			float:left;
			input.form-text {
				width:10rem;
				height:1.875rem;
				background-color:$header-search-field-bg-color;
				color:$header-search-field-text-color;
				box-shadow:$header-search-field-box-shadow;
				border:$header-search-field-border;
				border-radius:$header-search-field-border-radius;
				font:$header-search-field-font;
				padding:1.125rem 1rem 1.125rem 2rem;
				&::placeholder {
					color: $white;
				}
			}
		}
		.form-actions {
			float:left;
			margin-top:0.75rem;
			@include media(880px) {
				margin-bottom:4rem;
			}
			input.form-submit {
				background: transparent $search no-repeat scroll 0 0;
				background-size:14px;
				width:0.875rem;
				height:0.875rem;
				text-indent:-999em;
				overflow:hidden;
				padding:0;
				position:absolute;
				left:0.750rem;
				top:1.5rem;
				border: none;
			}
		}
	}
	.block-menu {
		float:left;
		margin-top:0;
		margin-right:0.5rem;
		@include media(880px) {
			margin-right:1.5rem;
			margin-bottom:1rem;
		}
		h2 {
			display: none;
		}
		li {
			display:inline-block;
			margin:0 1.875rem 0 0;
		}
		
		a {
			font:$header-menu-font;
			color:$header-menu-link-color;
			
			&:hover {
				color:$header-menu-link-hover;
			}
			// &:hover {
		  //  background-color:$nav-hover-bg-color;
		  //  color:$nav-hover-text-color;
			// }
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}
