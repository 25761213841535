.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
			margin-bottom: 3em;
			.views-exposed-widgets {
				display: flex;
				flex-flow: wrap;
				.views-exposed-widget {
					width: 50%;
					&#edit-keywords-wrapper {
						width: 100%;
					}
					&#edit-field-date-value-wrapper .form-item {
						width: 100%;
						#edit-field-date-value-value {
							width: 100%;
						}
					}
					select,
					input {
						min-width: 100%;
						box-shadow: none;
						border: 1px solid $light_gray;
					  -webkit-appearance: none;
					  -webkit-border-radius: 0px;
					}
					select {
					  background: $white $arrow-blue-down no-repeat scroll 50% 50% / 12px 12px;
					  background-position: right 10px center;
					  &:hover {
					  	cursor: pointer;
					  	background: $white $arrow-coral-down no-repeat scroll 50% 50% / 12px 12px;
						  background-position: right 10px center;
						  border: 1px solid $coral_red;
					  }
					}
					.form-submit {
						margin-top: 1.750em;
					}
				}
				@include media(880px) {
					.views-exposed-widget {
						width: 25%;
						&#edit-keywords-wrapper {
							width: 25%;
						}
					}
				}
			}
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}
