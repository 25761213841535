$section-row-padding          : $vert-space*1.5 0 !default;
$section-inner-mobile-padding : 0 $horz-space/2 !default;
$section-inner-padding        : 0 $horz-space !default;

@media (min-width: 540px) and (max-width:$wide) {
  .row-max-1 article:not(.node-teaser-image) {
    .card-logo,
    .card-image { 
      @include span-columns(3);
      margin-right: 0;
    }
  }
  .one-sidebar,
  .two-sidebars {
    .content-main,
    .content-additional {
      .row-max-1 article:not(.node-teaser-image) {
        .card-logo,
        .card-image { 
          @include span-columns(4);
          margin-right: 0;
        }
      }
    }
  }
}
@media (min-width:$wide) {
  .row-max-1 article:not(.node-teaser-image) {
    .card-logo,
    .card-image {
      @include span-columns(4);
      margin-right: 0;
    }
  }
  .one-sidebar,
  .two-sidebars {
    .content-main,
    .content-additional {
      .row-max-1 article:not(.node-teaser-image) {
        .card-logo,
        .card-image {
          @include span-columns(5);
          margin-right: 0;
        }
      }
    }
  }
}
