// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #444545; /* outer space */ 
$secondary         : #07395F; /* dark midnight blue */ 



// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */
$light_blue        : #84D0F5; /* lightblue */
$faded_blue        : #5D86C5; /* faded_blue */
$middle_red_purple : #38023B; /* middle_red_purple */
$yale_blue         : #0E54A3; /* yale_blue */
$yale_blue_dark    : #0C4B91; /* yale_blue_dark */
$coral_red         : #FB3640; /* coral_red */


// /* SEC PALETTE */ //
$light_gray        : #D3D3D3; /* light_gray */
$pale_grey         : #f5f8fa; /* pale_grey */
$pale_grey_two     : #e4e8eb; /* pale_grey_two */
$battleship_grey   : #6b7280; /* battleship_grey */
$japanese_indigo   : #6b7280; /* japanese_indigo */
$platinum          : #e0e0e2; /* platinum */
$nickel            : #6E7070; /* nickel */
$pearl_aqua        : #81D2C7; /* pearl_aqua */
$dark_lavender     : #6250A1; /*  dark_lavender  */
$purple_navy       : #496180; /*  purple_navy  */
$azureish_white    : #DCE3EB; /*  azureish_white  */
$anti_flash_white  : #F2F4F8; /*  anti_flash_white  */
$light_cyan        : #DFF0FD; /*  light_cyan  */

$black             : #000;
$grey              : #666;
$white             : #FFF;


// MENU
$middle_red					 : #E28871;
$pastel_pink				 : #E2B1A5;
$dust_storm					 : #E2C7C0;
$timberwolf          : #E2D8D5;
$denim               : #1066C7;
$charcoal            : #304055;
$independance      	 : #455366;
$darker_imperial_blue: #154468;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $yale_blue_dark;
$action-hover        : $coral_red;
$action-active       : $coral_red; 
$action-active-hover : $coral_red; 


// /* SHADOWS */ //
$box-shadow : 0 2px 4px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $primary-border-color;
$table-cell-border        : 1px solid $pale_grey;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $pale_grey;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow; 
$form-border-color     : $secondary-border-color;
$form-border           : 1px solid $secondary-border-color;
$form-border-radius    : 0;

$button-bg             : $white;
$button-hover-bg       : $action-hover;
$button-text           : $action-hover;
$button-border         : 2px solid $coral_red;
$button-border-radius  : 0;
$button-hover-text     : $white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;
